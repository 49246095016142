exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-utils-redirect-tsx": () => import("./../../../src/utils/redirect.tsx" /* webpackChunkName: "component---src-utils-redirect-tsx" */)
}

